
  .content_box{
    width: 100%;
    background: #ffffff;
    opacity: 1.0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    .tips{
      font-size: 16px;
      font-weight: 500;
      color: #e1251b;
    }
    .save_code{
      margin-top: 15px;
      color: #ffffff;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-size: 12px;
      font-weight: 700;
      background: #E1251B;
      border-radius: 20px;
      padding: 8px 20px;
    }
    .step{
      margin-top: 15px;
      color: #E1251B;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-size: 12px;
      font-weight: 700;
      border-radius: 20px;
      padding: 5px 20px;
      border: 1px solid #E1251B;
    }
    .step_one_text{
      margin-top: 15px;
      opacity: 0.54;
      font-size: 12px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
    }
    .step_two_text{
      width: 65%;
      line-height: 20px;
    }

    .step_text_color {
      color: red;
    }

    .step_text_margin{
      margin-top: 0px;
    }

  }

