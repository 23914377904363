
.product_container {
    margin: 10px 10px;
    background-color: white;
    border: 1px solid #f6f6f6;
    border-radius: 2px;
    box-shadow: 2px 2px 2px 2px #f6f6f6;
    box-sizing: border-box;
    padding: 5px;
    display:flex;
    flex-direction: row;

    .pro_img {
        width: 80px;
        height: 80px;
    }

    .productInfo {
        margin-left: 10px;
        margin-right: 10px;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .titleBox{
            color: #000000DE;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            line-height: 18px;
            font-size:12px;
            .product_type{
                padding: 2px 5px;
                border-radius: 5px;
                background: #e1251b;
                color: #ffffff;
                font-size: 12px;
            }
        }

        .numsBox{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .coupon{
                position: relative;
                display: inline-flex;
                align-items: center;
                
                .couponText,.couponMoney{
                    font-size:12px;
                    color: #ffffff;
                    text-align: center;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 5px;
                    background-color: #E1251B;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }


            .nums{
                display: flex;
                flex-direction: row;
                .nBox{
                    font-size: 10px;
                    color:#A9A9A9;
                }
            }


        }

        .priceBox {
            
            .newPrice {
                display: inline-block;
                color: #D7362E;
                font-size: 18px;
                margin-top: 2px;
            }

            .oldPrice {
                display: inline-block;
                color: #A9A9A9;
                font-size: 12px;
                text-decoration:line-through;
            }

            .buy {
                float: right;
                width: 60px;
                height: 25px;
                background: #e1251b;
                border-radius: 5px;
                color: white;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }


        }



    }
}

.product_box {
    width: 130px;
    margin-left: 10px;
    border: 1px solid #f6f6f6;
    border-radius: 2px;
    .box_image {
        width: 130px;
        height: 130px;
        margin-bottom: 3px;
    }

    .box_info{
        margin: 0px 5px;
        .title {
            max-width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 3px;
            color: black;
        }
        
        .price{
            margin-bottom: 5px;
            
            .price_newer {
                float: left;
                color: #D7362E;
                position: relative;
            }
            
            .price_older {
                margin-top: 2px;
                position: relative;
                float: right;
                color: #A9A9A9;
                font-size:11px;
                text-decoration:line-through;
            }
        }
    }

    .numsBox{
        margin-bottom: 5px;
        .coupon{
            position: relative;
            display: inline-flex;
            align-items: center;
    
            .couponText,.couponMoney{
                font-size:12px;
                color: #ffffff;
                text-align: center;
                background-color: #E1251B;
                padding-left: 5px;
                padding-right: 5px;
                height: 24px;
                line-height: 24px;
                border-radius:5px;
            }
    
    
        }
    }

}


